<template>
  <div class="col-md-4 ml-auto mr-auto">
    <card
      class="card-lock text-center"
      no-footer-line
    >
      <img
        slot="header"
        src="img/emilyz.jpg"
        alt="..."
      >
      <h4 class="card-title">
        Joe Gardner
      </h4>
      <fg-input
        type="password"
        placeholder="Enter Password.."
      />
      <n-button
        slot="footer"
        type="primary"
        round
        wide
      >
        Unlock
      </n-button>
    </card>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
